import React, { useEffect, useState } from 'react';
import { Button, Form, Layout, Table, Input, Row, Col, message } from 'antd';
import './App.css';
import logo from './logo.svg';
import axios from 'axios';
import { Menu, Breadcrumb } from 'antd';
// using ES6 modules
import { BrowserRouter, Route, Link, Redirect, Switch } from 'react-router-dom';

import { HddOutlined, WarningTwoTone, SafetyOutlined } from '@ant-design/icons';
import Inventory from './components/Inventory';
import StickerGenerator from './components/StickerGenerator';
import SurveyData from './components/SurveyData';
import PartnerCoupons from './components/PartnerCoupons';

const { Footer, Sider } = Layout;
const { SubMenu } = Menu;

const { Content, Header } = Layout;

function App() {
  const [loggedin, setLoggedin] = useState(false);
  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false);
  const [products, setProducts] = useState([
    {
      inventory: 499,
      sku: 'PILL_VC',
      updatedAt: new Date().toISOString(),
      id: 2,
    },
  ]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Current Quantity',
      dataIndex: 'inventory',
      key: 'inventory',
      render: (i, dat) => {
        return (
          <div style={{ display: `flex` }}>
            {i - dat.alert_at <= 0 && (
              <div>
                <WarningTwoTone twoToneColor="red" />
              </div>
            )}

            <div style={{ marginLeft: `10px` }}> {i}</div>
          </div>
        );
      },
    },
    {
      title: 'Reorder Quantity',
      dataIndex: 'alert_at',
      key: 'alert_at',
    },
  ];

  useEffect(() => {
    getProductsData();
    var loginStorageItem = localStorage.getItem('loggedIn');
    if (loginStorageItem) {
      setLoggedin(true);
    }
  }, []);
  const getProductsData = () => {
    axios
      .get(
        'https://138ft16bba.execute-api.ap-south-1.amazonaws.com/prod/products'
      )
      .then((res) => {
        let data = res.data;

        data.sort(function (a, b) {
          var keyA = a.inventory,
            keyB = b.inventory;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        setProducts(res.data);
      });
  };

  if (!loggedin) {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <div style={{ width: `360px`, margin: `0 auto`, marginTop: `20vh` }}>
          <Form
            style={{
              background: `white`,
              padding: `30px`,
              alignItems: `center`,
            }}
            onFinish={(v) => {
              if (v.password === 'Supp@2020') {
                setLoggedin(true);
                localStorage.setItem('loggedIn', 'true');
                return;
              }
              message.error(
                'Incorrect password. Please contact the tech department.'
              );
              return;
            }}
          >
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                padding: `20px`,
              }}
            >
              <img src={logo} style={{ height: `30px` }} />
            </div>
            <p
              style={{
                fontSize: `18px`,
                fontWeight: `300`,
                textAlign: `center`,
              }}
            >
              Login to Supp Admin
            </p>
            <Form.Item name="username">
              <Input type="username" placeholder="Enter username"></Input>
            </Form.Item>
            <Form.Item name="password">
              <Input type="password" placeholder="Enter password"></Input>
            </Form.Item>
            <div style={{ textAlign: `right` }}>
              <Button htmlType="submit" type="primary">
                <SafetyOutlined />
                Login
              </Button>
            </div>
          </Form>
        </div>
        <Footer style={{ textAlign: 'center' }}>Supp Nutrition ©2020</Footer>
      </Layout>
    );
  }

  return (
    <BrowserRouter>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          theme="light"
          collapsed={sideMenuCollapsed}
          onCollapse={(c) => setSideMenuCollapsed(c)}
        >
          <div
            style={{
              height: `64px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <img src={logo} style={{ height: `25px` }} />
          </div>
          <Menu defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1" icon={<HddOutlined />}>
              <Link to="/">Inventory</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/sticker-generator">Sticker Generator</Link>
            </Menu.Item>
            <SubMenu key="sub1" title="Orders">
              <Menu.Item key="3">
                <a
                  href="https://supp.retool.com/apps/Supp%20Orders%20Dashboard"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  In-transit Orders
                </a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="4">
              <a
                href="  https://drive.google.com/file/d/1W7UBNOhJx6WEULweQPrvSGohiOGctcDG/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Survey Data
              </a>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/partner-coupons">Partner Coupons</Link>
            </Menu.Item>
            <Menu.Item key="6">
              <a
                href="https://bidzcart.com/mw/customer/index.php/campaigns/regular"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cold Emailing
              </a>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }} />
          <Content style={{ margin: '0 16px' }}>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/inventory" />
              </Route>
              <Route path="/inventory">
                <Inventory />
              </Route>
              <Route path="/sticker-generator">
                <StickerGenerator />
              </Route>
              <Route path="/partner-coupons">
                <PartnerCoupons />
              </Route>
              {/* <Route path="/survey-data"> */}
              {/* <SurveyData /> */}
              {/* </Route> */}
            </Switch>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Supp Nutrition ©2020</Footer>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
