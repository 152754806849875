import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Breadcrumb,
  Select,
  Input,
  InputNumber,
  message,
} from 'antd';
import productsJSON from './../data/products.json';
import axios from 'axios';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function StickerGenerator() {
  const [loading, setLoading] = useState(false);
  const [pills, setPills] = useState([]);
  const [firstName, setFirstName] = useState(null);
  const [number, setNumber] = useState(null);
  const [mrp, setMrp] = useState(499);
  const [boxCount, setBoxCount] = useState(1);
  const [partner, setPartner] = useState('none');

  const available_pills = () => {
    return productsJSON.map((product) => {
      return {
        sku: product.sku,
        name: product.name,
      };
    });
  };
  const generateSticker = async () => {
    console.log('here');

    if (pills && pills.length == 0) {
      message.error('Please select the pills');
      return;
    }
    if (!firstName) {
      message.error('Please enter a Customer Name');
      return;
    }
    if (!number) {
      message.error('Please enter an Order Number');
      return;
    }
    if (!mrp) {
      message.error('Please enter a MRP');
      return;
    }
    if (!boxCount) {
      message.error('Please enter the boxCount');
      return;
    }
    setLoading(true);
    const line_items = [...pills, 'PBOX'];
    try {
      const axiRes = await axios.post(
        'https://sticker-generator.supp.in/generate',
        {
          items: line_items,
          boxCount: boxCount,
          mrp: mrp,
          firstName: firstName,
          orderNumber: number,
          partner,
        }
      );
      function downloadURI(uri, name) {
        var link = document.createElement('a');
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', name);
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      downloadURI(axiRes.data.link, `STK_${number}.pdf`);
      message.success('Sticker download successful.');
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Admin</Breadcrumb.Item>
        <Breadcrumb.Item>Sticker Generator</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        {...layout}
        style={{
          width: `600px`,
          background: `white`,
          marginTop: `20px`,
          padding: `30px`,
        }}
        layout="horizontal"
        initialValues={{ remember: true }}
        onFinish={(v) => {
          console.log(v);
          generateSticker(v);
        }}
      >
        <Form.Item label="Customer Name" name="customer_name">
          <Input
            onChange={(v) => {
              setFirstName(v.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Order Number" name="number">
          <Input
            onChange={(v) => {
              setNumber(v.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="MRP" style={{ textAlign: `left` }} name="mrp">
          <InputNumber
            min={1}
            max={100000}
            value={mrp}
            onChange={(v) => {
              setMrp(v);
            }}
          />
        </Form.Item>
        <Form.Item label="Pills" name="pills">
          <Select mode="multiple" onChange={(v) => setPills(v)}>
            {available_pills().map((pill) => {
              return (
                <Select.Option key={pill.sku} value={pill.sku}>
                  {pill.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="No. of boxes (pdfs)"
          style={{ textAlign: `left` }}
          name="box_count"
        >
          <InputNumber
            min={1}
            max={6}
            value={boxCount}
            onChange={(v) => {
              setBoxCount(v);
            }}
          />
        </Form.Item>
        <Form.Item label="Partner" name="partner">
          <Select onChange={(v) => setPartner(v)} defaultValue={partner}>
            <Select.Option value={'none'}>None</Select.Option>
            <Select.Option value={'healthians'}>Healthians</Select.Option>
          </Select>
        </Form.Item>
        <div style={{ textAlign: `right` }}>
          <Button htmlType="submit" type="primary" loading={loading}>
            Generate and Download
          </Button>
        </div>
      </Form>
    </div>
  );
}
