import React, { useEffect, useState } from 'react';
import { Layout, Table, Input, Row, Col } from 'antd';
import axios from 'axios';
import { Menu, Breadcrumb } from 'antd';
// using ES6 modules
import { BrowserRouter, Route, Link } from 'react-router-dom';

import { WarningTwoTone } from '@ant-design/icons';

export default function Inventory() {
  const [products, setProducts] = useState([
    {
      inventory: 499,
      sku: 'PILL_VC',
      updatedAt: new Date().toISOString(),
      id: 2,
    },
  ]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Current Quantity',
      dataIndex: 'inventory',
      key: 'inventory',
      render: (i, dat) => {
        return (
          <div style={{ display: `flex` }}>
            {i - dat.alert_at <= 0 && (
              <div>
                <WarningTwoTone twoToneColor="red" />
              </div>
            )}

            <div style={{ marginLeft: `10px` }}> {i}</div>
          </div>
        );
      },
    },
    {
      title: 'Reorder Quantity',
      dataIndex: 'alert_at',
      key: 'alert_at',
    },
  ];

  useEffect(() => {
    getProductsData();
  }, []);

  const getProductsData = () => {
    axios
      .get(
        'https://138ft16bba.execute-api.ap-south-1.amazonaws.com/prod/products'
      )
      .then((res) => {
        let data = res.data;

        data.sort(function (a, b) {
          var keyA = a.inventory,
            keyB = b.inventory;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        setProducts(res.data);
      });
  };

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Admin</Breadcrumb.Item>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        {/* {!loggedin && (
                <>
                  <Form
                    onFinish={(v) => {
                      if (v.password === 'Supp@2020') {
                        setLoggedin(true);
                        localStorage.setItem('loggedIn', 'true');
                        return;
                      }
                      message.error(
                        'Incorrect password. Please contact Supp tech team.'
                      );
                      return;
                    }}
                  >
                    <Form.Item name="password">
                      <Input
                        type="password"
                        placeholder="Enter password"
                      ></Input>
                    </Form.Item>
                    <Button htmlType="submit" type="primary">
                      Login
                    </Button>
                  </Form>
                </>
              )} */}

        <>
          <br />
          <Row justify="left">
            <Col>
              <Table
                style={{ width: `900px` }}
                rowKey={(record) => record.id}
                dataSource={products}
                columns={columns}
                pagination={{ pageSize: 100 }}
              />
            </Col>
          </Row>
        </>
      </div>
    </>
  );
}
