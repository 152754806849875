import React, { useState, useEffect } from 'react';
import {
  Menu,
  Breadcrumb,
  Button,
  Table,
  Input,
  Row,
  Col,
  message,
} from 'antd';
import axios from 'axios';
import Modal from 'antd/lib/modal/Modal';

export default function PartnerCoupons() {
  const [coupons, setCoupons] = useState([]);
  const [newCouponData, setNewCouponData] = useState({
    code: null,
    actual_price: null,
    discount_price: null,
    minimum_cart_value: null,
    notes: null,
    offer_price: null,
    parterId: null,
  });
  const [modalLoading, setModalLoading] = useState(false);
  const [showNewCouponModal, setShowNewCouponModal] = useState(false);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Actual Price',
      dataIndex: 'actual_price',
      key: 'actual_price',
    },
    {
      title: 'Discount Price',
      dataIndex: 'discount_price',
      key: 'discount_price',
    },
    {
      title: 'Minimum Cart Value',
      dataIndex: 'minimum_cart_value',
      key: 'minimum_cart_value',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Offer Price',
      dataIndex: 'offer_price',
      key: 'offer_price',
    },
    {
      title: 'Partner Ids',
      dataIndex: 'partnerIds',
      key: 'partnerIds',
      render: (a) => {
        return a.join(', ') || '';
      },
    },
  ];

  const handleCouponCreation = () => {
    if (newCouponData.code) {
      setModalLoading(true);
      const axiRes = axios
        .put(
          'https://ymr5xs6461.execute-api.ap-south-1.amazonaws.com/prod/admin/partner-coupons',
          {
            ...newCouponData,
          }
        )
        .then((res) => {
          setShowNewCouponModal(false);
          setModalLoading(false);
          getCouponsData();
          message.success('Coupon added');
        });
    } else {
      message.error('No coupon code provided!');
    }
  };
  const getCouponsData = async () => {
    const axiRes = axios
      .get(
        'https://ymr5xs6461.execute-api.ap-south-1.amazonaws.com/prod/admin/partner-coupons'
      )
      .then((res) => {
        setCoupons(res.data);
      });
  };

  useEffect(() => {
    getCouponsData();
  }, []);

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Admin</Breadcrumb.Item>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <br />
        <Button
          type="primary"
          onClick={() => {
            setShowNewCouponModal(true);
          }}
        >
          Add
        </Button>
        <br />
        <br />
        <Row justify="left">
          <Col>
            <Table
              style={{ width: `900px` }}
              rowKey={(record) => record.id}
              dataSource={coupons}
              columns={columns}
              pagination={{ pageSize: 100 }}
            />
          </Col>
        </Row>
      </div>
      <Modal
        visible={showNewCouponModal}
        onCancel={(_) => setShowNewCouponModal(false)}
        title="Add Partner Coupon"
        confirmLoading={modalLoading}
        onOk={() => {
          handleCouponCreation();
        }}
      >
        <div>
          <Input
            type="text"
            placeholder="Coupon Code"
            onChange={(e) =>
              setNewCouponData({ ...newCouponData, code: e.target.value })
            }
            style={{ marginTop: `10px` }}
          />
          <Input
            type="text"
            placeholder="Partner Id"
            onChange={(e) =>
              setNewCouponData({ ...newCouponData, parterId: e.target.value })
            }
            style={{ marginTop: `10px` }}
          />
          <Input
            type="text"
            placeholder="Actual Price"
            onChange={(e) =>
              setNewCouponData({
                ...newCouponData,
                actual_price: e.target.value,
              })
            }
            style={{ marginTop: `10px` }}
          />
          <Input
            type="text"
            placeholder="Discount Price"
            onChange={(e) =>
              setNewCouponData({
                ...newCouponData,
                discount_price: e.target.value,
              })
            }
            style={{ marginTop: `10px` }}
          />
          <Input
            type="text"
            placeholder="Minimum Cart Value"
            onChange={(e) =>
              setNewCouponData({
                ...newCouponData,
                minimum_cart_value: e.target.value,
              })
            }
            style={{ marginTop: `10px` }}
          />
          <Input
            type="text"
            placeholder="Notes"
            onChange={(e) =>
              setNewCouponData({ ...newCouponData, notes: e.target.value })
            }
            style={{ marginTop: `10px` }}
          />
          <Input
            type="text"
            placeholder="Offer Price"
            onChange={(e) =>
              setNewCouponData({
                ...newCouponData,
                offer_price: e.target.value,
              })
            }
            style={{ marginTop: `10px` }}
          />
        </div>
      </Modal>
    </div>
  );
}
